.communication-card {
  background-color: #f5f5f5;
  padding: 15px 10px;
  margin: 30px 0px;
  color: #333;
  display: flex;
  align-items: center;
  flex-direction: column;

  .top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px; }

  &.icon-false {
    flex-direction: column;
    align-items: flex-start; }

  &.information {
    padding: 16px;
    flex-direction: column;
    align-items: flex-start; }

  &.warning {
    background-color: #FEFAED;
    border: 2px solid #FF8726;
    border-radius: 5px; }

  &.error {
    background-color: rgba(170, 0, 0, 0.05);
    border: 2px solid #AA0000;
    border-radius: 5px;
    align-items: flex-start;

    svg path {
      fill: #AA0000; }

    a {
      font-weight: 800; } }

  .title {
    font-size: 18px;
    font-family: 'Poppins Semibold', sans-serif;
    font-weight: 400; }

  .copy {
    font-size: 16px; }

  a {
    white-space: nowrap;
    color: #333;
    &:hover,
    &:active,
    &:focus-visible {
        text-decoration: none; } }

  svg {
    margin-right: 10px;
    min-width: 40px;
    min-height: 40px; } }




