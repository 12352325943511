.modal {
    background-color: rgba(221, 236, 221, 0.9);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    padding-left: 13px;
    padding-right: 13px;

    &--hidden {
        display: none; } }

.modal-content__wrapper {
    background-color: #fff;
    padding: 20px;
    z-index: 10;
    max-width: 740px;
    width: 100%;
    box-shadow: 0 0 32px 0 rgba(40, 40, 35, 0.15);
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative; }

.modal-title {
    font-size: 30px;
    font-weight: bold;
    color: #5F6062;
    display: flex;
    align-items: center;
    + .modal-subtitle {
        font-size: 18px; } }

.modal-content {
    width: 100%;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto; }

.error-msg-phone {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 599px) {
        flex-direction: column;
        margin-bottom: 20px; } }

.error-call-number-box {
    display: flex; }

.error-msg-phone-box {
    border: 1px solid #8F9091;
    flex: 1;
    padding: 20px;
    margin: 0px 5%;
    border-radius: 6px;
    @media screen and (max-width: 599px) {
        margin-bottom: 20px; } }

.error-phone-icon {
    max-width: 25px;
    margin-right: 15px; }

.error-phone-number {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px; }

.modal-close__button {
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    -webkit-appearance: none;
    padding: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    svg {
        width: 16px; } }

.modal-buttons__wrapper {
    display: flex;
    margin-top: 20px;
    @media screen and (max-width: 600px) {
        flex-direction: column; } }

.modal-outline__button {
    color: #008700;
    min-width: 160px;
    border: 2px solid #008700;
    background-color: #fff;
    border-radius: 100px;
    -webkit-appearance: none;
    font-size: 16px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    height: 65px;
    font-family: 'Poppins Semibold';
    padding-left: 25px;
    padding-right: 25px;
    @media screen and (max-width: 600px) {
        height: 50px; } }

.modal-subtitle {
    margin-bottom: 10px;
    font-size: 30px;
    color: #5F6062; }

.modal-button + .modal-button {
    margin-left: 20px;
    @media screen and (max-width: 600px) {
        margin-left: 0;
        margin-top: 20px; } }

.modal-cta__button {
    border-radius: 100px;
    -webkit-appearance: none;
    font-size: 16px;
    display: flex;
    align-items: center;
    border: 2px solid #008700;
    background-color: #008700;
    color: #fff;
    cursor: pointer;
    padding-left: 25px;
    justify-content: center;
    height: 65px;
    font-family: 'Poppins Semibold';
    padding-right: 25px;
    @media screen and (max-width: 600px) {
        height: 50px; } }

.modal-icon__wrapper {
    background-color: #00A200;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 21px;
    width: 21px;
    margin-right: 10px;
    svg {
        width: 10px;
        fill: #fff; } }

.modal-columns {
    margin-left: -16px;
    margin-right: -16px;
    display: flex;
    @media screen and (max-width: 650px) {
        flex-direction: column; } }

.right-column,
.left-column {
    padding-left: 16px;
    padding-right: 16px;
    width: 50%;
    @media screen and (max-width: 650px) {
        width: 100%; } }

.right-column {
    @media screen and (max-width: 650px) {
        margin-top: 20px;
        width: 100%; } }

.modal-details__table {
    border: 1px solid #DFDFDF;
    td {
        padding: 10px 13px;
        border-bottom: 1px solid #DFDFDF;
        border-right: 1px solid #DFDFDF; } }
