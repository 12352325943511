.timeslot__btn {
    appearance: none;
    border-radius: 3px;
    border: 1px solid #10710E;
    height: 44px;
    font-size: 16px;
    color: #2F4F2D;
    width: 80px;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    &.m--active {
        font-family: 'Poppins Semibold';
        background-color: #10710E;
        color: #fff; } }

.day-part__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    grid-gap: 15px;
    border-top: 1px solid #DFDFDF;
    padding-left: 26px;
    padding-bottom: 16px; }

.day-part__title {
    grid-column: 1 / -1;
    display: block;
    appearance: none;
    border: none;
    font-size: 16px;
    color: #10710E;
    cursor: pointer;
    outline: none;
    padding: 0;
    text-align: left;
    font-family: 'Poppins Semibold';
    margin: 0;
    padding-top: 16px;
    background-color: transparent; }

.timeslots__wrapper {
    padding: 0 24px; }
