.boolean-filters__wrapper {
    display: flex;
    margin-bottom: 15px; }

.filters__wrapper {
    padding: 25px;
    background-color: #f2f2f2;
    @media screen and (max-width: 800px) {
        padding: 12px;
        display: none;
        margin-top: 12px; } }

.pattern-gym {
    height: 30px;
    background-repeat: no-repeat;
    background-position: right top;
    background-image: url('../static/img/pattern-gym.jpg');
    @media screen and (max-width: 800px) {
        display: none; } }

.product-booked {
    display: flex;
    align-items: center;
    color: #00A200; }

.booked-icon {
    border-radius: 50%;
    display: flex;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center;
    background-color: #00A200;
    margin-right: 8px;
    svg {
        fill: #fff;
        width: 8px; } }

.class__column {
    display: flex;
    flex-direction: column;
    height: 100%; }

.gym-header {
    padding: 17px 20px;
    background-color: #DDECDD;
    color: #10710E;
    @media screen and (max-width: 800px) {
        padding: 17px 12px; } }

.filter-toggle__button {
    color: #5F6062;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border: none;
    -webkit-appearance: none;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    background-color: #f2f2f2;
    margin-top: 0;
    cursor: pointer;
    display: none;
    @media screen and (max-width: 800px) {
        display: flex;
        padding-left: 12px;
        padding-right: 12px; }
    &.m--open {
        svg {
            transform: rotate(180deg); }
        + .filters__wrapper {
            display: block; } }
    svg {
        width: 12px;
        fill: #5F6062; } }

.toggle-class-overlay__button {
    position: absolute;
    right: 0;
    width: 34px;
    bottom: 0;
    top: 75px;
    background-color: #ddd;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    z-index: 10;
    svg {
        width: 10px;
        transform: rotate(90deg);
        transition: transform 0.5s ease;
        fill: #00A200; } }

.m--cta-wrapper-open + .toggle-class-overlay__button svg {
    transform: rotate(270deg); }

.cta-background.m--cancel_booking + .view-details__link {
    display: none; }

.showing-date {
    margin-top: 20px; }
