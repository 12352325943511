.m--hospital {
  .pattern {
    height: 30px;
    background-repeat: no-repeat;
    background-position: right top;
    background-image: url('../static/img/pattern-beauty.jpg');
    @media screen and (max-width: 800px) {
      display: none; } }

  .header {
    padding: 17px 20px;
    background-color: #DDECDD;
    color: #10710E;
    @media screen and (max-width: 800px) {
      padding: 17px 12px; } }

  .terms-checkbox {
    display: block;

    .custom-checkbox__label {
      display: inline-flex; } }

  .cta__wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #D4D4D4;
    border-left-width: 0;
    @media screen and (max-width: 800px) {
      border-left-width: 1px;
      border-top-width: 0;
      justify-content: center;
      padding: 20px 10px; } } }

