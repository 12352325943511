.google-location-finder {
  width: 100%;
  @media screen and (min-width: 800px) {
    max-width: 400px; }

  .input-box {
    position: relative;

    .close-button {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer; } }

  #location-finder-input {
    display: block;
    height: 50px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 16px;
    border: 1px solid #5F6062; }

  .with-clear {
    padding-right: 45px; }

  .google-location-finder-results {
    background-color: #fff;
    width: 100%;
    box-shadow: 0px 5px 5px 0px #DFDFDF;

    .placeList {
      list-style: none;
      padding: 0;
      margin: 0; } } }
