.m--beauty {
    .pattern {
        height: 30px;
        background-repeat: no-repeat;
        background-position: right top;
        background-image: url('../static/img/pattern-beauty.jpg');
        @media screen and (max-width: 800px) {
            display: none; } }

    .header {
        padding: 17px 20px;
        background-color: #DDECDD;
        color: #10710E;

        @media screen and (max-width: 800px) {
            padding: 17px 12px; } } }
