.location-finder-container {

  .search-container {
    background-color: #f5f5f5;
    padding: 20px 20px 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .label {
      display: block;
      font-size: 18px;
      margin-bottom: 10px;
      font-family: 'Poppins Semibold';
      color: #333; }

    .helper-text {
      font-size: 16px; }

    .desktop-container {
      width: 100%;
      @media screen and (min-width: 800px) {
        display: flex;
        align-items: baseline;
        gap: 20px; }

      p {
        font-size: 16px;
        color: #5f6062;

        @media screen and (max-width: 800px) {
          padding-top: 10px; }

        .m--use_current_location {
          font-size: 16px;
          color: #5f6062;

          &:hover {
            text-decoration: none;
            color: #000; } } } } }

  .results {
    background-color: #fff;
    padding: 30px 15px;
    display: grid;
    gap: 20px;

    @media screen and (min-width: 800px) {
      padding: 30px 30px;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px; }
    @media screen and (min-width: 1200px) {
      padding: 30px 100px;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px; } }

  @media screen and (max-width: 800px) {
    .locationFinderCard-container:nth-of-type(n+4) {
      display: none; } }

  @media screen and (min-width: 800px) {
    .locationFinderCard-container:nth-of-type(n+7) {
      display: none; }

    &.hide {
      display: none; } }

  .location-finder-cta-wrapper {
    display: flex;
    justify-content: center;

    &.hide {
      display: none; } } }
