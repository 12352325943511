.m--consultant-confirm {
	.pattern {
		height: 30px;
		background-repeat: no-repeat;
		background-position: right top;
		background-image: url('../static/img/pattern-recover.jpg');
		@media screen and (max-width: 800px) {
			display: none; } }

	.header {
		padding: 17px 20px;
		background-color: #DDECDD;
		color: #5F6062;
		@media screen and (max-width: 800px) {
			padding: 17px 12px; } }

	.booked {
		display: flex;
		font-size: 30px;
		color: #008700;
		align-items: center;
		font-family: 'Nuffield Bold';
		margin-bottom: 30px;
		svg {
			width: 12px;
			fill: #fff; }
		.icon__wrapper {
			background-color: #008700;
			border-radius: 50%;
			flex-shrink: 0;
			width: 25px;
			height: 25px;
			margin-right: 10px;
			display: flex;
			align-items: center;
			justify-content: center; } }

	.details {
		border: 1px solid #dfdfdf;
		th, td {
			border: 1px solid #dfdfdf;
			padding: 15px; } }

	.details__wrapper {
		display: grid;
		grid-template-columns: 500px 1fr;
		grid-gap: 30px;
		padding-bottom: 45px;
		margin-top: 10px;
		@media screen and (max-width: 1000px) {
			grid-template-columns: 1fr; } }

	.add-to-calendar-link__wrapper {
        display: flex;
        margin-top: 20px;
        font-family: 'Poppins Semibold';
        svg {
            width: 12px;
            fill: #00A200;
            margin-left: 20px; } }

	.react-add-to-calendar__button {
		cursor: pointer; }

	.cancelation-text {
        align-self: stretch; } }
