.health-assessment-slots-container {

  .page-title {
    font-size: 24px;
    margin-bottom: 10px;
    font-family: 'Nuffield Bold'; }

  .intro-text {
    margin-bottom: 30px;
    font-size: 18px; }

  .location-container {
    padding-bottom: 35px;

    .title {
      font-size: 16px;
      margin-bottom: 10px;
      font-family: 'Poppins Semibold'; }

    .location-sub-container {
      display: flex;
      justify-content: space-between;
      @media screen and (min-width: 800px) {
        justify-content: flex-start; }

      .location-name {
        font-size: 18px;
        padding-right: 10px;
        @media screen and (min-width: 800px) {
          padding-right: 50px; } }

      .secondary-btn {
        @media screen and (max-width: 800px) {
          min-width: 145px; }

        @media screen and (max-width: 650px) {
          height: 60px; } } } }

  .divider {
    border: 1px solid #DFDFDF;
    margin: 15px 0px 20px 0px; }

  .practitioner-gender-field-wrapper, {
    select {
      max-width: 270px; } }

  .please-note {
    border-radius: 6px;
    border: 2px solid #085482;
    padding: 16px;
    background: #F3F9FC;
    color: #333;
    margin-bottom: 25px;

    p {
      font-family: 'Poppins Semibold';
      font-size: 18px; }

    ul {
      list-style-type: disc;
      padding-top: 10px; } }

  .calendar {
    padding-bottom: 50px; }

  .card {
    display: flex;
    border: 1px solid #DFDFDF;
    margin-top: 30px;

    .time {
      border-right: 1px solid #DFDFDF;
      padding: 15px 25px;
      font-size: 18px;
      white-space: nowrap;
      font-family: 'Poppins Semibold';
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 30%;
      @media screen and (max-width: 800px) {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #DFDFDF;
        align-items: flex-start; }

      .slot-date {
        font-family: 'Poppins Regular';
        margin: 5px 0;
        text-align: center; }

      span {
        display: block; } }

    @media screen and (max-width: 800px) {
      flex-direction: column;
      justify-content: left;
      border-right: 0;
      padding-bottom: 0;
      border-bottom: 1px solid #DFDFDF;
      align-items: start;
      margin: 15px 25px;
      padding: 15px 0;

      .slot-date {
        text-align: left; }

      span {
        display: inline-block;
        margin-right: 5px; } } }

  .details {
    padding: 30px 0px 30px 30px;
    width: 100%;
    @media screen and (max-width: 800px) {
      padding-top: 20px 15px; }

    .detail {
      display: flex;
      padding-bottom: 15px;

      .location-icon-wrapper {
        margin-right: 20px;
        display: flex;
        width: 16px;
        align-items: center;
        justify-content: center;
        svg {
          width: 16px;
          fill: #00A200; } }

      .person-icon-wrapper {
        margin-right: 20px;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 16px;
          fill: #00A200; } }

      .practitioner-gender {
        text-transform: capitalize; } } }

  .cta__wrapper {
    margin-left: auto;
    display: flex;
    align-items: center;
    width: 155px;
    padding: 30px 30px 30px 0px;
    justify-content: center;
    @media screen and (max-width: 800px) {
      width: 100%;
      border-top: 1px solid #DFDFDF;
      padding-top: 15px;
      padding-bottom: 15px; } }

  .primary-btn {
    width: 140px; } }


