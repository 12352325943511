.ha-bookings {
    p {
        margin-top: 10px;
        margin-bottom: 10px; }

    table {
        width: 100%; }

    .h2 {
        margin-bottom: 10px; }

    .no-items-found {
        text-align: center;
        padding: 30px 12px; }

    .add-to-calendar-link__wrapper {
        display: flex;
        margin-top: 20px; }
    a {
        cursor: pointer;
        font-family: 'Poppins Semibold'; }

    .cancellation-message {
        width: 100%;
        border: 1px solid #ff8726;
        padding: 10px;
        border-radius: 5px;
        background-color: #fef6f1;

        @media only screen and (max-width: 800px) {
            width: 100%; } }

    .ha-tile-header {
        display: flex;
        justify-content: space-between;
        align-items: center; }

    .location-icon__wrapper {
        margin-right: 12px;
        width: 19px;

        svg {
            width: 18px;
            fill: #00A200; } }


    .btn-container {
        display: flex;
        margin-top: 20px;

        button {
          margin-right: 10px; } }

    .cancellation-container {

        .cancellation-actions {
            display: flex;
            margin-top: 20px;
            margin-bottom: 20px;

            @media only screen and (max-width: 800px) {
                display: block; } }

        a {
            font-weight: 500;
            color: #5F6062; } }

    .primary-btn {
        padding-right: 20px;
        padding-left: 20px;
        text-decoration: none;
        display: flex;
        align-items: center;

        &.m--red {
            min-width: 200px;
            margin-right: 20px; } } }
