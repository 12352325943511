$index: 10;

@while $index <= 50 {
	.mt-#{$index} {
		margin-top: #{$index}px; }
	.mb-#{$index} {
		margin-bottom: #{$index}px; }
	.pt-#{$index} {
		padding-top: #{$index}px; }
	.pb-#{$index} {
		padding-bottom: #{$index}px; }
	$index: $index + 5; }
