.health-assessment-confirm-container {

  .page-title {
    font-size: 24px;
    margin-bottom: 10px;
    font-family: 'Nuffield Bold'; }

  .phone-switch-button {
    margin-top: 40px;
    font-size: 16px;
    color: #5F6062;
    text-decoration: underline;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0; }

  .intro-text {
    padding: 15px 0px;
    p {
      padding-bottom: 10px;
      font-size: 18px; } }

  .address-fields {
    max-width: 800px; }

  .zipcode-field__wrapper {
    margin-top: 20px;
    input, select {
      max-width: 240px; } }

  .page-subtitle {
    font-size: 18px;
    margin-bottom: 15px;
    font-family: 'Poppins Semibold'; }

  .appointment-card {
    padding: 20px 15px;
    border: 1px solid #DFDFDF;
    @media screen and (min-width: 800px) {
      display: flex;
      justify-content: stretch;
      padding: 0px; }

    .top {
      @media screen and (min-width: 800px) {
        border: 1px solid #DFDFDF;
        padding: 20px 15px;
        width: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; } }

    .bottom {
      @media screen and (min-width: 800px) {
        border: 1px solid #DFDFDF;
        padding: 30px 15px;
        width: 100%; } }

    .time {
      font-size: 18px;
      margin-bottom: 5px;
      font-family: 'Poppins Semibold'; }

    .date {
      font-size: 16px;
      margin-bottom: 5px;
      @media screen and (min-width: 800px) {
        text-align: center; } }

    .line {
      border: 1px solid #DFDFDF;
      margin: 15px 0px 20px 0px;
      @media screen and (min-width: 800px) {
        display: none; } }

    .type {
      font-size: 20px;
      margin-bottom: 20px;
      font-family: 'Poppins Semibold'; }


    .location,
    .gender {
      font-size: 16px;
      margin-bottom: 15px;
      display: flex;
      svg {
        width: 20px;
        fill: #00A200;
        margin-right: 10px; }
      &-name {
        text-transform: capitalize; } } }

  .divider {
    border: 1px solid #DFDFDF;
    margin: 30px 0px; }

  .phone-field-wrapper {
    input, select {
      max-width: 400px; } }

  .communication-wrapper {
    background-color: #f5f5f5;
    padding: 24px;
    margin: 30px 0px 40px 0px; }

  .cancellation-title {
    color: #5F6062;
    font-family: 'Poppins Semibold';
    margin-bottom: 12px; }

  .cancellation-text {
    padding-bottom: 55px; }

  .special-requirements-field {
    width: 706px; }

  .special-requirements-title {
    color: #5F6062;
    font-family: 'Avenir-Black';
    margin-bottom: 12px; }

  .special-requirements-text {
    padding-bottom: 10px;
    width: 706px; }

  .health-assessment-confirm-ctas-wrapper {
    display: flex;
    align-items: center;
    margin-top: 40px; } }

.checkbox-group {
  .custom-checkbox__wrapper {
    @media screen and (max-width: 400px) {
      margin-left: 40px; }
    &:first-child {
      margin-left: 0; } } }
