.health-assessment-details-container {

  .page-title {
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 20px;
    font-family: 'Nuffield Bold'; }

  .communication-wrapper {
    padding-top: 15px;
    p {
      padding-bottom: 20px;
      font-size: 18px; } }

  .form-field {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;

    .gender-field-wrapper,
    .sex-at-birth-field-wrapper {
      input, select {
        max-width: 400px; } } }

  .ctas-wrapper {
    display: flex;
    align-items: center;
    margin-top: 40px; } }
