.pattern {
  height: 30px;
  background-repeat: no-repeat;
  background-position: right top;
  background-image: url('../static/img/pattern-recover.jpg'); }

.header__wrapper {
  padding-bottom: 15px;

  .header {
    padding: 17px 20px;
    background-color: #DDECDD;
    color: #10710E;
    display: flex;
    align-items: center;

    h1 {
      font-size: 32px; }

    .ha-confirmed-checkmark {
      display: flex;
      justify-content: center;
      background-color: #3E7D34;
      min-width: 24px;
      min-height: 24px;
      border-radius: 100px;
      margin-right: 10px;

      svg {
        width: 12px;
        fill: #fff; } } } }
