.healthAssessmentType {
  border: 1px solid #DFDFDF;

  .mobile-desktop-container {
    @media screen and (min-width: 800px) {
      display: flex;
      justify-content: space-between; }

    .top-container {
      padding: 30px 15px;
      @media screen and (min-width: 800px) {
        width: 50%; } }

    .title {
      font-size: 20px;
      margin-bottom: 20px;
      font-family: 'Poppins Semibold'; }

    .time,
    .modules {
      font-size: 16px;
      display: flex;
      svg {
        width: 20px;
        fill: #2EA836;
        margin-right: 10px; } }

    .time {
      margin-bottom: 15px; }

    .bottom-container {
      border: 1px solid #DFDFDF;
      padding: 20px 15px;
      display: flex;
      gap: 30px;
      text-align: center;
      justify-content: center;
      @media screen and (min-width: 800px) {
        align-items: center;
        justify-content: flex-end;
        width: 50%;
        border: none; }

      .callUsNow-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-appearance: none;
        padding-left: 5px;
        padding-right: 5px;
        background-color: #3e7d34;
        color: #fff;
        font-size: 16px;
        border: 2px solid #3e7d34;
        cursor: pointer;
        transition: all 0.5s ease;
        font-family: 'Poppins Semibold';
        outline: none;
        width: 150px;
        height: 40px;
        border-radius: 100px;
        text-decoration: none;
        @media screen and (min-width: 800px) {
          width: 200px;
          height: 55px; }
        &:hover,
        &:focus-visible {
          background-color: #2f4f2d;
          border: 2px solid #2f4f2d; } }

      .tertiary-btn {
        color: #5F6062;
        font-size: 16px;

        svg {
          fill: #546062;
          width: 10px;
          margin-left: 10px; }

        &.hideDetails {
          svg {
            transform: rotate(180deg); } }

        @media screen and (min-width: 800px) {
          width: 200px;
          height: 55px;
          margin-right: 30px; } } } }


  .bookableOnline-false {
    background-color: #E3EDE2;
    color: #333;
    text-align: center;
    font-size: 16px;
    padding: 10px 15px;
    font-weight: 500;
    a {
      color: #333; } }

  .details {
    border: 1px solid #DFDFDF;
    padding: 25px 15px;
    display: grid;
    gap: 25px;

    &.hide {
      display: none; } } }


