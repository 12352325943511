.unavailable-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F5F5F5;
  border: 1px solid #DFDFDF;
  padding: 50px 35px;
  text-align: center;
  @media screen and (min-width: 800px) {
    flex-direction: row;
    text-align: left; }

  svg {
    @media screen and (min-width: 800px) {
      width: 72px;
      height: 72px;
      margin-right: 25px; } }


  .responsive-container {
    @media screen and (min-width: 800px) {
      flex-direction: column; } }

  .title {
    color: #333;
    font-size: 18px;
    margin: 35px 0px 10px 0px;
    font-family: 'Poppins Semibold';
    @media screen and (min-width: 800px) {
      margin-top: 0px; } }

  .text {
    a {
      color: #5f6062;
      font-weight: 800;
      &:hover {
        text-decoration: none; } } } }
