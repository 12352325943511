.m--consultant-form {
	.pattern {
		height: 30px;
		background-repeat: no-repeat;
		background-position: right top;
		background-image: url('../static/img/pattern-recover.jpg');
		@media screen and (max-width: 800px) {
			display: none; } }

	.header {
		padding: 17px 20px;
		background-color: #DDECDD;
		color: #5F6062;
		@media screen and (max-width: 800px) {
			padding: 17px 12px; } }

	.under18-disclaimer {
		a {
			color: #5F6062; }
		.bold {
			font-family: 'Poppins Semibold'; } }

	.fields {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 30px;
		@media screen and (max-width: 800px) {
			grid-template-columns: 1fr; }
		.patient_age {
			margin-top: 5px; } }

	.page-title {
		font-size: 24px;
		margin-bottom: 10px;
		font-family: 'Nuffield Bold'; }

	.form-row {
		display: flex;
		margin: 15px auto 40px;
		.form-radio-block {
			max-width: 440px;
			overflow: hidden;
			position: relative;

			.radio-block-options {
				display: flex;
				.radio-block-option {
					position: relative;
					flex-grow: 1;
					margin: 15px 0px; }
				.radio-block-option:last-of-type {
					margin-left: 30px; }
				input {
					position: absolute;
					opacity: 0; }

				label {
					font-size: 16px;
					line-height: 1.333;
					text-transform: none;
					background: #fff;
					border: 1px solid #00a200;
					border-radius: 3px;
					margin: 0;
					padding: 15px 20px;
					text-align: center;
					-moz-transition: all 180ms ease-in;
					transition: all 180ms ease-in; }

				input:checked+label {
					font-family: "Poppins Semibold", sans-serif;
					background: #00a200;
					border-color: #DDEBDD;
					color: #fff; } } } }

	.communication__wrapper {
		background-color: #f5f5f5;
		padding: 24px;
		margin-top: 30px; }

	.remaining-fields {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 30px;
		a {
			color: #5F6062; }
		p {
			padding-top: 10px; } }

	.cash-or-credit {
		background-color: #f5f5f5;
		padding: 16px 24px; }

	.zipcode-field__wrapper,
	.gender-field__wrapper {
		input, select {
			max-width: 240px; }
		a {
			color: #5F6062; } }


	.ctas__wrapper {
		display: flex; }

	.back-btn {
		appearance: none;
		background-color: transparent;
		margin: 0;
		padding: 0;
		text-decoration: underline;
		border: none;
		font-family: 'Poppins Semibold';
		font-size: 18px;
		outline: none; }

	.primary-btn {
		margin: 0;
		margin-right: 30px;
		width: 212px;
		height: 65px;
		border-radius: 100px; }

	li {
		list-style-type: disc;
		margin-left: 19px; } }
