// Nuffield Sans font - Headings
@font-face {
  font-family: 'Nuffield Regular';
  font-display: swap;
  src:
    url('../fonts/NuffieldSans-Regular.eot') format('eot'),
    url('../fonts/NuffieldSans-Regular.woff') format('woff'),
    url('../fonts/NuffieldSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nuffield Bold';
  font-display: swap;
  src:
    url('../fonts/NuffieldSans-Bold.eot') format('eot'),
    url('../fonts/NuffieldSans-Bold.woff') format('woff'),
    url('../fonts/NuffieldSans-Bold.ttf') format('truetype');
}

// Poppins font - Body
@font-face {
  font-family: 'Poppins Regular';
  src: url('../fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Italic';
  src: url('../fonts/Poppins-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Semibold';
  src: url('../fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Semibold Italic';
  src: url('../fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
}
