.ha-appointment-status {
    display: inline-block;
    padding: 5px 10px;
    font-size: 14px;
    vertical-align: middle;
    border-radius: 10px;

    @media only screen and (max-width: 800px) {
        position: relative;
        bottom: 50px; }

    &__green {
        background-color: #3e7d34;
        color: white; }

    &__light-green {
        background-color: #E3EDE2;
        color: #333333; }

    &__red {
        background-color: #AA0000;
        color: white; } }
