*, *:before, *:after {
    box-sizing: border-box; }

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #5F6062;
    font-family: 'Poppins Regular'; }

.page {
    width: 100%; }

.desktop-only {
    @media screen and (max-width: 800px) {
        display: none!important; } }

.mobile-only {
    @media screen and (min-width: 801px) {
        display: none!important; } }

.divider {
    height: 1px;
    background-color: #d4d4d4; }
