.place-item {
    padding: 12px;
    cursor: pointer;
    border-bottom: 1px solid #D4D4D4;
    color: #000;
    list-style-type: none;
    margin: 0px;

    &:hover,
    &:focus-visible {
        background-color: #DDECDD; } }
