.text__input {
    height: 50px;
    border: 1px solid #5F6062;
    border-radius: 3px;
    display: block;
    width: 100%;
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
    outline: none; }
