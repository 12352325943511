.custom-select__name {
    background-color: #fff;
    padding-left: 20px;
    padding-right: 35px;
    color: #5F6062;
    border-radius: 3px;
    width: 100%;
    border: 1px solid #6E6F71;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 16px;
    position: relative;
    cursor: pointer;
    text-align: left;
    .m--disabled & {
        border-color: #ddd;
        color: #ddd;
        svg {
            fill: #ddd; } }
    svg {
        width: 12px;
        position: absolute;
        fill: #5F6062;
        right: 20px;
        .m--custom-select-open & {
            fill: #008700;
            transform: rotate(180deg); }
        .custom-select__wrapper:hover & {
            fill: #008700; } } }

.selectbox-filters_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    @media screen and (max-width: 950px) {
        grid-template-columns: 1fr 1fr; }
    @media screen and (max-width: 550px) {
        grid-template-columns: 1fr; } }

.custom-select__wrapper {
    position: relative;
    &.m--disabled {
        pointer-events: none; } }

.custom-select__list {
    position: absolute;
    background-color: #fff;
    width: 260px;
    border: 1px solid #E2E2E2;
    box-shadow: 0 5px 9px 0 rgba(0,0,0,0.20);
    z-index: 10;
    top: 100%;
    margin-top: 10px;
    display: none;
    border-radius: 3px;
    max-height: 220px;
    overflow-y: auto;
    &:before {
        content: '';
        display: block;
        transform: skew(-45deg, 45deg);
        width: 10px;
        height: 10px;
        background-color: #fff;
        position: absolute;
        left: 40px;
        top: -5px;
        z-index: -1; }
    .m--custom-select-open & {
        display: block; } }

.custom-select__li {
    overflow: hidden;
    &:first-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px; }
    &:last-child {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px; } }

.custom-select__label {
    padding: 9px;
    position: relative;
    font-size: 18px;
    display: flex;
    z-index: 10;
    overflow: hidden;
    user-select: none;
    cursor: pointer;
    align-items: center;
    &:hover {
        background-color: #F5F5F5; } }
