.text__input {
    height: 50px;
    border: 1px solid #5F6062;
    border-radius: 3px;
    display: block;
    width: 100%;
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
    outline: none;
    &:focus {
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.50); }
    &[type=number] {
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0; } }

    .field--invalid & {
        border: 2px solid #AA0000;
        background-color: rgba(170, 0, 0, .05); } }

.textarea__input {
    border: 1px solid #5F6062;
    border-radius: 3px;
    display: block;
    width: 100%;
    font-size: 16px;
    font-family: Arial!important;
    outline: none;
    padding: 15px;
    &:focus {
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.50); }
    .field--invalid & {
        border: 2px solid #AA0000;
        background-color: rgba(170, 0, 0, .05); } }

.form-field__label {
    display: block;
    color: #5F6062;
    font-family: 'Poppins Semibold';
    margin-bottom: 12px; }

.form-field__helperText {
    color: #5F6062;
    margin-bottom: 12px; }

.address-GP-lookup-label {
    margin-bottom: 12px; }

.date-field__inner-wrapper {
    display: flex;
    width: 100%;
    max-width: 240px;
    input {
        flex-shrink: 0;
        flex-grow: 0;
        width: 100%; }
    input + input {
        margin-left: 15px; }
    .m--day {
        width: 60px; }
    .m--month {
        width: 60px; }
    .m--year {
        width: 90px; } }

.checkbox-group,
.radio-group {
    display: flex; }

.select__input {
    appearance: none;
    border: 1px solid #5F6062;
    border-radius: 3px;
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding-left: 15px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2300A200' viewBox='0 0 8.97 5.78'%3E%3Ctitle%3E%3C/title%3E%3Cpath d='M10.5,3.53,9.1,2.28l-3,3.16L2.91,2.21,1.53,3.46,6.06,8Z' transform='translate(-1.53 -2.21)'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 93% 52%;
    background-size: 12px;
    background-color: #fff;
    &:focus {
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.50);
        outline: none; }
    .field--invalid & {
        border: 2px solid #AA0000;
        background-color: rgba(170, 0, 0, .05); } }


.field-error {
    color: #AA0000;
    font-size: 16px;
    margin-top: 5px; }

.select-field__wrapper {
    position: relative; }

.select-icon__wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    fill: #00A200;
    right: 15px;
    svg {
        width: 12px; } }
