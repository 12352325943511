.health-assessment-type-container {

  .page-title {
    font-size: 32px;
    margin-bottom: 10px;
    font-family: 'Nuffield Bold'; }

  .intro-text {
    margin-bottom: 30px;
    font-size: 18px; }

  .card-container {
    display: grid;
    gap: 15px; } }
