.primary-btn {
  display: block;
  -webkit-appearance: none;
  border-radius: 22px;
  padding-left: 5px;
  padding-right: 5px;
  height: 42px;
  background-color: #3e7d34;
  color: #fff;
  font-size: 16px;
  border: 2px solid #3e7d34;
  cursor: pointer;
  transition: all 0.5s ease;
  font-family: 'Poppins Semibold';
  outline: none;

  &:hover,
  &:focus-visible {
    background-color: #2f4f2d;
    border: 2px solid #2f4f2d; }

  @media screen and (max-width: 800px) {
    margin-left: 0; }

  &.m--continue,
  &.m--confirm_booking {
    margin-right: 30px;
    width: 316px;
    height: 40px;
    border-radius: 100px;
    @media screen and (min-width: 800px) {
      width: 250px;
      height: 65px; } }

  &.m--select,
  &.m--book_now {
    width: 150px;
    height: 40px;
    border-radius: 100px;
    @media screen and (min-width: 800px) {
      width: 200px;
      height: 55px; } }

  &.m--select_location {
    width: 100%;
    height: 45px; }

  &.m--complete_your_pre-assessment {
    width: 316px;
    height: 40px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    @media screen and (min-width: 800px) {
      width: 350px;
      height: 65px; } }

  &.m--leave_waitlist {
    background-color: transparent;
    color: #00A200;
    border-color: #00A200; }

  &.m--cancel_booking {
    background-color: transparent;
    border: 2px solid #00A200;
    color: #00A200; }

  &.m--closed {
    background-color: transparent;
    color: #00A200; }

  &.m--see_more {
    width: 100%;
    margin-top: 30px;
    max-width: 300px;
    height: 65px;
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto; }

  &[disabled] {
    background-color: #DFDFDF;
    color: #5F6062;
    border: 2px solid #DFDFDF;
    pointer-events: none;
    user-select: none; }

  &.m--is_full {
    background-color: #A2A2A2;
    border: 2px solid #A2A2A2;
    color: #fff;
    font-size: 12px; }

  &.m--red {
    background-color: #e50806;
    border-color: #e50806; }

  &.m--survey-btn {
    background: #fff;
    border: 2px solid #3E7D34;
    border-radius: 3em;
    color: #3E7D34;
    text-align: center;
    width: 200px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    @media screen and (max-width: 600px) {
      width: auto;
      height: 65px; }

    &.m--survey-btn a {
      color: #00a200; } } }

.secondary-btn {
  font-size: 16px;
  line-height: 25px;
  color: #3e7d34;
  border: 2px solid #3e7d34;
  background-color: transparent;
  border-radius: 100px;
  width: 165px;
  height: 40px;
  font-family: 'Poppins Semibold';
  cursor: pointer;

  &:hover,
  &:focus-visible {
    color: #2f4f2d;
    border: 2px solid #2f4f2d; }

  &.m--manage_your_booking {
    width: 225px;
    height: 45px; } }

.tertiary-btn {
  appearance: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  border: none;
  font-family: 'Poppins Semibold';
  font-size: 18px;
  outline: none;
  cursor: pointer;

  &.m--view_details {
    display: flex;
    justify-content: center;
    align-items: center; }

  &:hover,
  &:focus-visible {
    text-decoration: none; } }
