.m--event-product {
    .cta__wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border: 1px solid #D4D4D4;
        border-left: 0;
        @media screen and (max-width: 800px) {
            position: absolute;
            bottom: 0;
            flex-direction: row-reverse;
            top: 75px;
            width: 100%;
            background-color: transparent;
            justify-content: space-around;
            padding: 0;
            transform: translateX(100%) translateX(-34px);
            transition: transform 0.5s ease, background-color 0.5s ease;
            border: none;
            &.m--cta-wrapper-open {
                background-color: #DDECDD;
                transform: translateX(0); }
            .m--past-bookings & {
                display: none; } } } }

.class__wrapper {
    margin-top: 20px;
    position: relative;
    z-index: 1;
    transition: padding 0.5s ease; }

.class-content__wrapper {
    position: relative;
    z-index: 10;
    background-color: #fff;
    display: grid;
    grid-template-columns: 170px 1fr 170px;
    @media screen and (max-width: 800px) {
        min-height: 130px;
        grid-template-columns: 1fr;
        overflow: hidden;
        grid-column-gap: 8px;
        grid-row-gap: 0; } }

.class-details__wrapper {
    padding: 15px 25px;
    border-top: 1px solid #D4D4D4;
    border-bottom: 1px solid #D4D4D4;
    @media screen and (max-width: 800px) {
        padding: 0 12px 12px 12px;
        border-top: 0;
        border-left: 1px solid #D4D4D4;
        border-right: 1px solid #D4D4D4; } }

.class__detail {
    display: flex;
    align-items: center;
    min-height: 25px;
    svg {
        width: 18px;
        fill: #008700;
        margin-right: 12px; }
    .class-image {
        width: 18px;
        margin-right: 12px; }
    &.m--align-bottom {
        margin-top: auto; } }

.class-date {
    font-size: 18px;
    @media screen and (max-width: 800px) {
        font-size: 14px; } }

.class-title {
    font-size: 24px;
    @media screen and (max-width: 800px) {
        margin-bottom: 20px; } }

.class-time__wrapper {
    border: 1px solid #D4D4D4;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    @media screen and (max-width: 800px) {
        padding: 12px 12px 0 12px;
        text-align: left;
        justify-content: flex-start;
        align-items: flex-start;
        border-bottom: 0; } }

.class-time {
    font-size: 18px;
    white-space: nowrap;
    @media screen and (max-width: 800px) {
        font-size: 14px; } }


.cta-background {
    display: flex;
    border-radius: 22px;
    flex-direction: column;
    width: 125px;
    &.m--has-waitlist {
        background-color: #FFEEBF;
        color: #785D19; } }
