.h1, h1 {
    font-size: 30px;
    font-family: 'Nuffield Regular'; }

.h2, h2 {
    font-size: 24px; }

.h3, h3 {
    font-size: 20px; }

.h4, h4 {
    font-size: 18px; }

.h5, h5 {
    font-size: 18px; }

.h6, h6 {
    font-size: 18px; }

.h2, h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6 {
    font-family: 'Nuffield Bold'; }


p, input, button, select {
    font-family: 'Poppins Regular'; }

.bold,
strong,
b {
    font-family: 'Poppins Semibold'; }

i {
    font-family: 'Poppins Italic'; }
