.wysiwyg {
	font-size: 16px;
	margin: 0;
	padding: 0;
	color: #5F6062;
	ul {
		list-style-type: none;
		li {
			list-style-type: none; } }
	ul, ol {
		padding-left: 50px;
		margin-bottom: 15px; }
	li + li {
		margin-top: 4px; }
	ul li::before {
		content: "\2022";
		color: #00A200;
		font-weight: bold;
		display: inline-block;
		width: 20px;
		font-size: 12px;
		margin-left: -30px; }
	ol {
		list-style-type: decimal; }

	b {
		font-weight: bold; }

	a {
		color: #5F6062;
		text-decoration: underline; }

	i {
		font-style: italic; } }

