.m--hmot {
    .pattern {
        height: 30px;
        background-repeat: no-repeat;
        background-position: right top;
        background-image: url('../static/img/pattern-health.jpg');
        @media screen and (max-width: 800px) {
            display: none; } }

    .header {
        padding: 17px 20px;
        background-color: #DDECDD;
        color: #10710E;
        @media screen and (max-width: 800px) {
            padding: 17px 12px; } }

    .home-gym__link {
        height: 65px;
        border-radius: 100px;
        background-color: #00A200;
        display: flex;
        width: 200px;
        color: #fff;
        align-items: center;
        justify-content: center;
        font-family: 'Poppins Semibold';
        font-size: 18px; }

    .gender__label {
        font-family: 'Poppins Semibold';
        margin-bottom: 3px; }

    .staff {
        display: flex;
        align-items: center;
        min-height: 25px;
        font-size: 18px;
        font-family: 'Poppins Semibold'; }

    .card {
        display: flex;
        border: 1px solid #DFDFDF;
        border-top: 0;
        padding: 25px 24px; }

    .cta {
        display: block;
        -webkit-appearance: none;
        border-radius: 22px;
        padding-left: 25px;
        padding-right: 25px;
        height: 42px;
        background-color: #00A200;
        color: #fff;
        font-size: 16px;
        border: 2px solid #00A200;
        cursor: pointer;
        font-family: 'Poppins Semibold';
        outline: none;
        &[disabled] {
            background-color: #F5F5F5;
            border: 2px solid #F5F5F5;
            pointer-events: none;
            user-select: none; }
        &.m--close {
            background-color: #fff;
            color: #00A200; }
        &.m--big {
            height: 65px;
            padding-left: 50px;
            padding-right: 50px;
            border-radius: 50px;
            margin-left: auto; } }

    .button__wrapper {
        margin-left: auto; }

    .wysiwyg {
        padding: 12px 20px 15px 12px;
        border: 1px solid #D4D4D4;
        border-top: 0;
        ul {
            list-style-type: disc;
            padding-left: 40px; } }

    .gender__wrapper {
        padding: 25px;
        background-color: #f2f2f2;
        .custom-select__wrapper {
            max-width: 275px; } }

    .no-staff-available {
        grid-column: 1 / -1; }

    .datepicker__wrapper {
        border: 1px solid #DFDFDF; } }
