.location-finder-card-container {
  border: 1px solid #5F6062;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  padding: 30px 20px;
  position: relative;

  &:hover {
    border: 1px solid #3E7D34; }

  .top {
    display: flex;
    justify-content: space-between;

    .location {
      font-size: 20px;
      line-height: 26px; }

    .distance {
      min-width: 70px;
      text-align: end;
      margin-left: 10px; } }

  .bottom {
    gap: 15px;

    .address {
      display: block;
      padding-bottom: 15px; }

    .m--select_location:after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      content: ""; } } }
