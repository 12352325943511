.m--gymclass-list {
    .no-items-found {
        text-align: center;
        padding: 30px 12px; }

    .class__wrapper {
        margin-top: 20px;
        position: relative;
        z-index: 1;
        transition: padding 0.5s ease; }

    .class-content__wrapper {
        position: relative;
        z-index: 10;
        background-color: #fff;
        display: grid;
        grid-template-columns: 170px 1fr 170px;
        @media screen and (max-width: 800px) {
            min-height: 130px;
            grid-template-columns: 1fr;
            overflow: hidden;
            grid-column-gap: 8px;
            grid-row-gap: 0; } }

    .class-details__wrapper {
        padding: 15px 25px;
        border-top: 1px solid #D4D4D4;
        border-bottom: 1px solid #D4D4D4;
        @media screen and (max-width: 800px) {
            padding: 0 12px 12px 12px;
            border-top: 0;
            border-left: 1px solid #D4D4D4;
            border-right: 1px solid #D4D4D4; } }

    .class__detail {
        display: flex;
        align-items: center;
        min-height: 25px;
        svg {
            width: 18px;
            fill: #008700;
            margin-right: 12px; }
        .class-image {
            width: 18px;
            margin-right: 12px; }
        &.m--align-bottom {
            margin-top: auto; } }

    .class-date {
        font-size: 18px;
        @media screen and (max-width: 800px) {
            font-size: 14px; } }

    .class-title {
        font-size: 24px;
        @media screen and (max-width: 800px) {
            margin-bottom: 20px; } }

    .class-time__wrapper {
        border: 1px solid #D4D4D4;
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        @media screen and (max-width: 800px) {
            padding: 12px 12px 0 12px;
            text-align: left;
            justify-content: flex-start;
            align-items: flex-start;
            border-bottom: 0; } }

    .class-time {
        font-size: 18px;
        white-space: nowrap;
        @media screen and (max-width: 800px) {
            font-size: 14px; } }

    .right-column {
        display: flex;
        align-items: center; }

    .cta__wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border: 1px solid #D4D4D4;
        border-left: 0;
        .m--gymclass.m--booked & {
            background-color: #FFF5EE;
            border-color: #FFF5EE; }
        @media screen and (max-width: 800px) {
            position: absolute;
            bottom: 0;
            flex-direction: row-reverse;
            top: 75px;
            width: 100%;
            background-color: transparent;
            justify-content: space-around;
            padding: 0;
            transform: translateX(100%) translateX(-34px);
            transition: transform 0.5s ease, background-color 0.5s ease;
            border: none;
            &.m--cta-wrapper-open {
                background-color: #DDECDD;
                transform: translateX(0); }
            .m--past-bookings & {
                display: none; } } }

    .class-status {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Poppins Semibold';
        width: 100%;
        height: 65px;
        padding-bottom: 21px;
        font-size: 14px;
        margin-bottom: -21px;
        &.mobile-only {
            height: auto;
            margin-bottom: 0;
            padding-bottom: 0;
            padding: 12px 12px 0 0;
            width: auto;
            font-size: 14px;
            margin-left: auto;
            position: absolute;
            top: 0;
            right: 0;
            .booked-icon {
                height: 17px;
                width: 17px;
                svg {
                    width: 7px; } }
            .class-status-waiting {
                background-color: #FFEEBF;
                color: #785D19;
                font-family: 'Poppins Semibold';
                border-radius: 12px;
                height: 24px;
                display: flex;
                align-items: center;
                padding-left: 10px;
                padding-right: 10px; }
            .class-status-regular {
                background-color: #FFE3CC;
                color: #785D19;
                font-family: 'Poppins Semibold';
                border-radius: 12px;
                height: 24px;
                display: flex;
                align-items: center;
                padding-left: 10px;
                padding-right: 10px; }
            .product-booked {
                background-color: #DDECDD;
                padding: 2px 15px;
                border-radius: 100px; } } }


    .cta-background {
        display: flex;
        border-radius: 22px;
        flex-direction: column;
        width: 125px;
        &.m--book {
            background-color: #FFEEBF;
            color: #785D19; }
        &.m--has-waitlist {
            background-color: #FFEEBF;
            color: #785D19; }
        &.m--is_full {
            background-color: #DFDFDF;
            color: #5F6062; }
        &.m--cancel_booking {
            background-color: #DDECDD; }
        &.m--logged_out {
            .class-status {
                display: none; } }
        &.m--leave_waitlist {
            background-color: #DDECDD;
            color: #008700; }
        &.m--to_early {
            white-space: nowrap;
            background-color: transparent;
            color: #5F6062;
            .primary-btn {
                display: none; } } }

    .class-what-to-bring {
        margin-top: 10px; }

    .view-details__link {
        display: flex;
        &:before {
            content: '';
            display: block;
            width: 18px;
            margin-right: 12px;
            @media screen and (max-width: 800px) {
                display: none; } }
        &.mobile-only {
            color: #00A200;
            font-family: 'Poppins Semibold';
            text-decoration: none;
            height: 42px;
            border: 2px solid #00A200;
            display: flex;
            align-items: center;
            border-radius: 100px;
            width: 125px;
            justify-content: center; } }


    .title-view-details__link {
        font-size: 12px;
        font-family: 'Poppins Regular';
        margin-left: 12px; }

    .add-to-calendar-link__wrapper {
        display: flex;
        margin-top: 20px;
        svg {
            width: 12px;
            fill: #00A200;
            margin-left: 20px; }
        a {
            cursor: pointer;
            font-family: 'Poppins Semibold'; } }

    .view-map-link {
        display: flex;
        cursor: pointer;
        align-items: center;
        font-family: 'Poppins Semibold';
        text-decoration: none;
        svg {
            width: 12px;
            fill: #00A200;
            margin-left: 20px; } } }
