.addressLookup__spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: addressLookup-spinner .6s linear infinite; }

.clickableLinks {
  text-decoration: underline;
  cursor: pointer;
  margin-top: 20px;
  display: inline-block; }

@keyframes addressLookup-spinner {
  to {
    transform: rotate(360deg); } }

.addressDisclaimerContainer {
  margin-top: 20px;
  background-color: #f5f5f5;
  p {
    font-family: 'Poppins Semibold', sans-serif;
    color: #5F6062;
    padding: 20px 25px; } }
