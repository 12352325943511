.health-assessment-locations-container {

  .page-title {
    font-size: 24px;
    margin-bottom: 10px;
    font-family: 'Nuffield Bold'; }

  .intro-text {
    margin-bottom: 30px;
    font-size: 18px; } }

