.product-confirm-modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 100; }

.therapist-filter__wrapper {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 30px;
    width: 100%;
    max-width: 425px; }

.no-staff-available {
    grid-column: 1 / -1; }

.therapist-filter__title {
    color: #5F6062;
    font-size: 24px;
    font-family: 'Poppins Semibold';
    margin-bottom: 10px; }

.product-confirm-modal-content {
    display: flex;
    flex-direction: column;
    background-color: white; }

.product-detail-modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center; }

.product-detail-modal-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: flex-end; }

.product {
    position: relative;
    background-color: #fff;
    display: grid;
    grid-template-columns: 170px 1fr 170px;
    margin-top: 20px;
    overflow: hidden;
    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr; } }

.time-wrapper {
    border: 1px solid #D8D8D8;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    white-space: nowrap;
    padding: 15px 25px;
    @media screen and (max-width: 800px) {
        padding: 12px;
        padding-bottom: 0;
        font-size: 14px;
        justify-content: flex-start;
        border-bottom: 0; } }

.bookable-product {
    display: flex;
    padding: 15px 24px;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #DFDFDF;
    &:last-child {
        border-bottom: 0; } }

.product-details {
    border-top: 1px solid #D4D4D4;
    border-bottom: 1px solid #D4D4D4;
    padding: 15px 25px;
    @media screen and (max-width: 800px) {
        border-top: 0;
        border-bottom: 0;
        border-left: 1px solid #D4D4D4;
        border-right: 1px solid #D4D4D4;
        padding: 0 12px 12px 12px; } }

.cta-wrapper {
    border: 1px solid #D4D4D4;
    border-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 15px;
    @media screen and (max-width: 800px) {
        border-left: 1px solid #D4D4D4; } }

.cta {
    display: block;
    -webkit-appearance: none;
    border-radius: 22px;
    padding-left: 25px;
    padding-right: 25px;
    height: 42px;
    background-color: #00A200;
    color: #fff;
    font-size: 16px;
    border: 2px solid #00A200;
    cursor: pointer;
    font-family: 'Poppins Semibold';
    &.m--close {
        background-color: #fff;
        color: #00A200; }
    &[disabled] {
        background-color: #F5F5F5;
        border: 2px solid #F5F5F5;
        pointer-events: none;
        user-select: none; } }

.product-title {
    font-size: 24px;
    margin-bottom: 10px; }

.availabilities-wrapper {
    border: 1px solid #D4D4D4;
    border-top: 0;
    padding-top: 10px; }

.products-loading {
    padding: 15px;
    text-align: center; }

.product-detail-modal,
.product-confirm-modal {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(221,236,221, 0.9);
    z-index: 10;
    padding: 15px; }

.product-detail-modal-content,
.product-confirm-modal-content {
    width: 100%;
    max-width: 800px;
    position: relative;
    padding: 45px 30px 30px 30px;
    box-shadow: 0 0 32px 0 rgba(40,40,35,0.15); }

.close-product-detail-modal__btn {
    appearance: none;
    position: absolute;
    top: 0;
    background-color: transparent;
    border: none;
    right: 0;
    padding: 15px;
    cursor: pointer;
    svg {
        width: 17px;
        fill: #fff; } }

.toc__checkbox {
    margin-top: auto; }

.prerequisites__wrapper {
    display: flex;
    flex-direction: column; }

.detail {
    display: flex; }

.details__table {
    border: 1px solid #dfdfdf;
    th, td {
        border: 1px solid #dfdfdf;
        padding: 15px; } }

.details__wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 30px;
    .primary-btn {
        width: 125px;
        margin-top: 20px; }
    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr; } }


.location-icon__wrapper {
    margin-right: 20px;
    display: flex;
    width: 16px;
    align-items: center;
    justify-content: center;
    svg {
        width: 16px;
        fill: #00A200; } }

.modal-details__wrapper {
    width: 100%; }

.price-icon__wrapper {
    margin-right: 20px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 10px;
        fill: #00A200; } }

.category-title {
    font-size: 30px;
    margin-top: 30px;
    font-family: 'Poppins Semibold'; }

.title-view-details__link {
    font-size: 12px;
    font-family: 'Poppins Regular';
    margin-left: 12px; }

.view-details__link {
    display: flex;
    &:before {
        content: '';
        display: block;
        width: 18px;
        margin-right: 12px;
        @media screen and (max-width: 800px) {
            display: none; } }
    &.mobile-only {
        color: #00A200;
        font-family: 'Poppins Semibold';
        text-decoration: none;
        height: 42px;
        border: 2px solid #00A200;
        display: flex;
        align-items: center;
        border-radius: 100px;
        width: 125px;
        justify-content: center; } }
