.back-btn-container {
  padding-bottom: 25px;
  display: flex;

  svg {
    width: 11px;
    height: 20px;
    fill: #00A200;
    transform: rotate(90deg); }

  button {
    color: #5F6062;
    font-size: 14px;
    text-decoration: underline;
    font-family: 'Poppins Regular';
    padding-left: 5px;

    &:hover {
      text-decoration: none; } } }
