.custom-radio__value {
    position: absolute;
    opacity: 0; }

.custom-radio__wrapper {
    position: relative;
    display: flex; }

.custom-radio__label {
    color: #5F6062;
    display: flex;
    align-items: center;
    user-select: none;
    a {
        color: #5F6062; } }

.custom-radio__faux {
    display: flex;
    justify-content: center;
    align-items: center;
    content: '';
    width: 30px;
    height: 30px;
    border: 1px solid #5F6062;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 4px;
    flex-shrink: 0;
    flex-grow: 0;
    &:before {
        content: "";
        width: 15px;
        border-radius: 50%;
        height: 15px;
        background-color: #fff;
        display: block; } }

.custom-radio__value {
    position: absolute;
    opacity: 0;
    &:focus + .custom-radio__label .custom-radio__faux {
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.50); }
    &:checked + .custom-radio__label .custom-radio__faux:before {
        background-color: #00A200; } }

.radio-group {
    display: flex;
    &.m--vertical {
        flex-direction: column;
        .custom-radio__wrapper {
            margin-left: 0;
            margin-top: 10px;
            &:first-child {
                margin-top: 0; } } }
    .custom-radio__wrapper {
        margin-left: 80px;
        &:first-child {
            margin-left: 0; } } }
