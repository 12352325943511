.gplookup__spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: gplookup-spinner .6s linear infinite; }

@keyframes gplookup-spinner {
  to {
    transform: rotate(360deg); } }
