.m--hospital {
  .timeslots__wrapper {
    padding: 0 24px;
    border-left: 1px solid #D4D4D4;
    border-bottom: 1px solid #D4D4D4;
    border-right: 1px solid #D4D4D4; }

  .day-part__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    grid-gap: 15px;
    border-top: 0;
    padding-left: 26px;
    padding-bottom: 16px; }

  .book-cta__wrapper {
    padding-left: 26px;
    padding-bottom: 15px;
    border-top: 1px solid #D4D4D4;
    padding-top: 15px;

    .primary-btn {
      width: 200px;
      height: 65px;
      border-radius: 100px;
      margin-left: auto; } }

  .no-timeslots {
    grid-column: 1 / end; } }

