.m--consultant {
	.pattern {
		height: 30px;
		background-repeat: no-repeat;
		background-position: right top;
		background-image: url('../static/img/pattern-recover.jpg');
		@media screen and (max-width: 800px) {
			display: none; } }

	.header {
		padding: 17px 20px;
		background-color: #DDECDD;
		color: #5F6062;

		@media screen and (max-width: 800px) {
			padding: 17px 12px; } }

	.card {
		display: flex;
		border: 1px solid #DFDFDF;
		margin-top: 30px;
		@media screen and (max-width: 800px) {
			flex-direction: column; } }

	.time {
		border-right: 1px solid #DFDFDF;
		padding: 15px 25px;
		font-size: 18px;
		white-space: nowrap;
		font-family: 'Poppins Semibold';
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		.slotDate {
			font-family: 'Poppins Regular';
			margin: 5px 0;
			text-align: center;
			span {
				display: block; } }
		@media screen and (max-width: 800px) {
			flex-direction: column;
			justify-content: left;
			border-right: 0;
			padding-bottom: 0;
			border-bottom: 1px solid #DFDFDF;
			align-items: start;
			margin: 15px 25px;
			padding: 15px 0;
			.slotDate {
				text-align: left;
				span {
					display: inline-block;
					margin-right: 5px; } } } }

	.datepicker {
		border: 1px solid #DFDFDF; }

	.cta__wrapper {
		margin-left: auto;
		display: flex;
		align-items: center;
		width: 155px;
		padding-right: 15px;
		justify-content: center;
		@media screen and (max-width: 800px) {
			width: 100%;
			border-top: 1px solid #DFDFDF;
			padding-top: 15px;
			padding-bottom: 15px; } }

	.primary-btn {
		width: 140px; }

	.details {
		padding: 16px 24px;
		@media screen and (max-width: 800px) {
			padding-top: 0; } }

	.location-icon__wrapper {
		margin-right: 20px;
		display: flex;
		width: 16px;
		align-items: center;
		justify-content: center;
		svg {
			width: 16px;
			fill: #00A200; } }

	.location__wrapper div {
			font-family: 'Poppins Semibold'; }

	.price-icon__wrapper {
		margin-right: 20px;
		width: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			width: 10px;
			fill: #00A200; } }

	.person-icon__wrapper {
		margin-right: 20px;
		width: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			width: 16px;
			fill: #00A200; } }

	.detail {
		display: flex; } }
