.tabs__wrapper {
    display: inline-flex;
    margin-bottom: 30px;
    border: 1px solid #00A200;
    overflow: hidden;
    border-radius: 3px; }

.tab__item {
    -webkit-appearance: none;
    border: none;
    font-size: 16px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 43px;
    cursor: pointer;
    outline: none;
    background-color: #fff;
    &.m--active {
        background-color: #00A200;
        color: #fff;
        font-family: 'Poppins Semibold'; } }

.divider {
    display: block;
    border-bottom: 1px solid #D4D4D4; }
