.health-assessment-confirmed-container {
  padding-bottom: 50px;

  .page-title {
    font-size: 24px;
    margin-bottom: 25px;
    margin-top: 35px;
    font-family: 'Nuffield Bold'; }

  .table {
    border: 1px solid #D4D4D4;

    th, td {
      border: 1px solid #D4D4D4;
      padding: 15px;

      .address-line-one {
        font-weight: 800; } }

    .map-link {
      text-decoration: underline;
      color: #5f6062;
      &:hover {
        text-decoration: none; } } }

  .link-wrapper {
      display: flex;
      margin-top: 35px;
      font-family: 'Poppins Semibold';
      font-size: 16px;
      svg {
          width: 12px;
          fill: #00A200;
          margin-left: 20px; }
      a {
        text-decoration: none;
        cursor: pointer;
        color: #5f6062;
        &:hover {
          text-decoration: underline; } } }

  .divider {
    border: 1px solid #D4D4D4;
    margin: 35px 0px 20px 0px; }

  .h3 {
    font-size: 18px;
    font-family: 'Poppins Semibold';
    padding-bottom: 15px; }

  .text {
    a {
      color: #5f6062;
      &:hover {
        text-decoration: none; } } }

  .cancellation-text {
    a {
      color: #5f6062;
      &:hover {
        text-decoration: none; } } }

  .help-us-improve {
    background: #F5F5F5;
    padding: 20px;
    gap: 10px;
    margin-top: 40px;
    a {
      color: #3E7D34;
      &:hover {
        text-decoration: none; } }
    p {
      color: black; }
    h3 {
      font-size: 20px;
      font-weight: 900;
      text-align: left;
      color: black;
      margin: 0 0 10px 0; } } }

li {
  list-style-type: disc;
  margin-left: 19px; }
