.days-toggle__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, 80px);
    grid-gap: 15px;
    grid-auto-flow: column;
    position: relative;
    min-width: 100%;
    justify-content: space-between; }

.days-toggle__scroll {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    flex-grow: 1;
    padding: 12px 0; }

.day-toggle__button {
    border: 1px solid #10710E;
    outline: none;
    background-color: #fff;
    -webkit-appearance: none;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
    width: 80px;
    white-space: nowrap;
    height: 50px;
    cursor: pointer;
    &.m--active {
        color: #fff;
        background-color: #10710E;
        font-family: 'Poppins Semibold'; }
    &.m--disabled {
        background-color: #F5F5F5;
        color: #A2A2A2;
        font-family: 'Poppins Semibold';
        border-color: #A2A2A2;
        pointer-events: none; } }

.month-navigation__wrapper {
    display: flex;
    align-items: center;
    height: 53px;
    background-color: #DDECDD;
    padding-left: 18px;
    padding-right: 18px; }

.month-name {
    font-family: 'Poppins Semibold';
    font-size: 16px;
    width: 90px;
    color: #10710E;
    text-align: center; }

.prev-month__btn,
.next-month__btn {
    cursor: pointer;
    appearance: none;
    display: flex;
    align-items: center;
    outline: none;
    border: none;
    color: inherit;
    background-color: transparent;
    svg {
        height: 12px;
        fill: #00A200; } }

.prev-period__btn,
.next-period__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    padding: 0 10px;
    width: 35px;
    flex-grow: 0;
    flex-shrink: 0;
    svg {
        height: 12px;
        fill: #00A200; } }

.days-toggle__outer-wrapper {
    display: flex;
    padding: 0 14px; }
