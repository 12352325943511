.location-filter__wrapper {
  padding: 18px 18px 40px 50px;
  border-bottom: solid 1px #DFDFDF;
  label {
    margin-left: 5px; }
  input[type="checkbox"] {
    accent-color: #00A200;
    width: 18px;
    height: 18px;
    vertical-align: bottom;
    margin-right: 5px; }
  ul {
    li {
      margin-top: 15px;
      display: inline-block;
      margin-right: 25px;
      margin-left: 5px;
      white-space: nowrap; } } }

.location__label {
    font-family: 'Poppins Semibold';
    font-size: 16px;
    color: #10710E; }
.location__name {
    margin-top: 10px; }
