.input-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr; } }

.subscription-box {
    background-color: #f5f5f5;
    padding: 24px; }

.event-form {
    .custom-checkbox__label {
        color: #5F6062; } }

.cta {
    &.m--big {
        height: 65px;
        padding-left: 40px;
        padding-right: 40px;
        border-radius: 33px; } }

.back-link {
    font-family: 'Poppins Semibold';
    text-decoration: underline;
    appearance: none;
    background-color: transparent;
    border: none;
    font-size: 16px;
    margin-left: 40px; }

.cta-group {
    display: flex; }
