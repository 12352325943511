.custom-checkbox__value {
    position: absolute;
    opacity: 0; }

.custom-checkbox__wrapper {
    position: relative;
    display: flex; }

.custom-checkbox__label {
    color: #5F6062;
    display: flex;
    align-items: center;
    user-select: none;
    flex-wrap: wrap;
    white-space: pre-wrap;
    a {
        color: #5F6062; } }

.custom-checkbox__faux {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    content: '';
    width: 16px;
    height: 16px;
    border: 1px solid #5F6062;
    background-color: #fff;
    margin-right: 4px;
    svg {
        width: 12px;
        fill: #fff; } }

.custom-checkbox__value {
    position: absolute;
    opacity: 0;
    &:focus + .custom-checkbox__label .custom-checkbox__faux,
    &:focus + .custom-select__label .custom-checkbox__faux {
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.50); }
    &:checked + .custom-checkbox__label svg,
    &:checked + .custom-select__label svg {
        fill: #00A200; } }

.custom-radio__value {
    &:checked + .custom-select__label {
        background-color: #DDECDD; } }


.checkbox-group {
    display: flex;
    &.m--vertical {
        flex-direction: column;
        .custom-checkbox__wrapper {
            margin-left: 0;
            margin-top: 10px;
            &:first-child {
                margin-top: 0; } } }
    .custom-checkbox__wrapper {
        margin-left: 80px;
        &:first-child {
            margin-left: 0; } } }
